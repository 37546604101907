.app-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.appetta__container {
    background-color: #fff;
    padding: 20px;
    overflow-y: auto!important;
}
