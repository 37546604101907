.menu-link {
  margin-right: 30px;
  display: inline-block;
  color: var(--gray);
  position: relative;
  text-decoration: none;
  font-size: 20px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    background-color: var(--teal);
    width: 0%;
    height: 3px;
  }

  &:hover {
    color: var(--gray);
    text-decoration: none;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      background-color: var(--teal);
      width: 100%;
      height: 3px;
      transition: width 0.3s;
    }
  }

  &--active {
    color: var(--gray) !important;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      background-color: var(--teal);
      width: 100%;
      height: 3px;
    }
  }
}
