@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import "./themes/palette.scss";

body {
  margin: 0;
  font-family: 'VT323', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#tsparticles {
  position: absolute;
  z-index: -100;
}


