.resume {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  margin-left: 20%;
  margin-top: 30px;
  margin-bottom: 30px;

  b {
    font-size: 18px;
  }

  svg {
    color: var(--purple);
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
  }

  &__indent {
    padding-left: 25px;
  }
}
